.home-container {
  section.hero {
    padding: 1.6rem;
    div.left {
      & > h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 46px;
        color: #272343;
        margin-bottom: 2rem;
        span {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            height: 15px;
            width: 98%;
            bottom: 2px;
            left: 6px;
            background: #68d585;
            z-index: -1;
          }
        }
      }
      & > p {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #2d334a;
        margin-bottom: 2.4rem;
      }

      .buttons button {
        font-weight: 600;
        border: none;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        padding: 1.2rem;
        background: #e7aa07;
        border-radius: 40px;
        color: white;
        width: 150px;
        margin-bottom: 4.8rem;

        svg {
          margin-left: 1rem;
        }
        &:last-child {
          display: none;
        }
      }
    }

    & > button {
      font-weight: 600;
      border: 2px solid #272343;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      padding: 1.2rem;
      background-color: #fff;
      border-radius: 51px;
      width: 100%;
      margin-bottom: 4.8rem;
      color: #272343;
      margin-left: auto;
      margin-right: auto;
      max-width: 480px;
      display: block;

      svg {
        margin-left: 1rem;
      }
    }

    & > div.right {
      position: relative;
      overflow: hidden;
      margin-bottom: 2rem;
      background: linear-gradient(
        161.09deg,
        rgba(111, 101, 227, 0.5) 6.74%,
        rgba(231, 170, 7, 0.4) 43.76%,
        rgba(231, 170, 7, 0) 74.57%
      );
      border-radius: 60px 60px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-top: 10rem;
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;

      img {
        position: relative;
        width: 220px;
        left: 15px;
      }
      div.text {
        padding: 1.6rem;
        position: absolute;
        background: linear-gradient(
          360deg,
          rgba(255, 255, 255, 0.6) -33.52%,
          rgba(253, 253, 253, 0.2) 294.89%
        );
        box-shadow: 0px -1px 8px rgba(198, 198, 198, 0.25);
        backdrop-filter: blur(8px);
        width: 100%;
        h3 {
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #11202a;
          margin-bottom: 1rem;
        }
        p {
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          color: #2c353b;
        }
      }
    }
  }

  section.impact {
    width: 100%;
    padding: 1.6rem;
    text-align: center;
    & > h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-transform: capitalize;
      color: #272343;
      display: inline-block;
      margin-bottom: 2.4rem;
    }
    .wrapper {
      padding-top: 2rem;
      padding-bottom: 2rem;
      border-top: 1px solid #d2d2d2;
      border-bottom: 1px solid #d2d2d2;
      display: flex;
      justify-content: stretch;
      max-width: 475px;
      margin-left: auto;
      margin-right: auto;

      .impact-item {
        display: inline-block;
        position: relative;
        padding-left: 1rem;
        padding-right: 1rem;
        flex: 1;
        &:not(:last-child)::after {
          content: "";
          position: absolute;
          right: 0;
          height: 30px;
          width: 1px;
          background-color: #d2d2d2;
          top: 50%;
          transform: translateY(-50%);
        }

        h3 {
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          text-transform: capitalize;
          color: #272343;
        }
        em {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-transform: capitalize;
          color: #2d334a;
        }
      }
    }
  }

  section.needs {
    padding-top: 2.8rem;
    max-width: 1200px;
    margin: 4rem auto;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0.4;
      background-image: url("./../../assets/images/home_what_do_you_need_bg.png");
      z-index: 0;
    }

    h3 {
      position: relative;
      padding-left: 2.8rem;
      padding-right: 2.8rem;
      text-align: center;
      font-weight: 700;
      font-size: 26px;
      line-height: 40px;
      margin-bottom: 2.4rem;
      text-transform: capitalize;
      background: linear-gradient(
        92.11deg,
        #7165e3 48.23%,
        rgba(251, 215, 0, 0) 159.7%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    em {
      padding-left: 2.8rem;
      padding-right: 2.8rem;
      position: relative;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      display: block;
      max-width: 718px;
      margin: 0 auto;
      margin-bottom: 7.2rem;
    }

    .tabs {
      position: relative;
      padding: 0.8rem 0;
      margin-left: 1.6rem;
      border-bottom: 1px solid #d2d2d2;
      margin-bottom: 2rem;
      overflow-x: auto;
      white-space: nowrap;
      button {
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        text-transform: capitalize;
        color: #2d334a;
        background-color: transparent;
        border: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        padding: 1.2rem;
        margin-right: 1rem;

        &.active {
          color: #fff;
          background: #e7aa07;
          border-radius: 47px;
          padding: 0.8rem 2.4rem;
        }
      }
    }
  }

  section.security {
    margin-top: 4rem;
    padding: 1.6rem;
    position: relative;
    z-index: 1;
    text-align: center;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0.6;
      background-image: url("./../../assets/images/checked_purple_bg.png");
      z-index: 0;
    }

    h3 {
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      margin-bottom: 1.6rem;
      line-height: 40px;
      text-transform: capitalize;
      background: linear-gradient(
        92.11deg,
        #7165e3 48.23%,
        rgba(251, 215, 0, 0) 159.7%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    em {
      position: relative;
      font-family: "Lato";
      font-style: normal;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #2d334a;
    }

    div.boxes {
      margin-top: 6rem;
      margin-bottom: 4rem;
      .box {
        &:not(:last-child) {
          margin-bottom: 5rem;
        }
        &:first-child h5 {
          color: #7165e3;
        }
        &:nth-child(2) h5 {
          color: #e7aa07;
        }
        &:last-child h5 {
          color: #68d585;
        }
        display: inline-block;
        h5 {
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 1.6rem;
        }

        p {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #2d334a;
        }
      }
    }
  }

  section.features {
    margin: 0 auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
    position: relative;
    z-index: 1;

    & > h3,
    & > em {
      display: none;
    }

    div.content {
      padding: 1.6rem;
      position: relative;
      background: #272343;
      padding-bottom: 2rem;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.6;
        background-image: url("./../../assets/images/checked_purple_bg.png");
        z-index: 0;
      }

      img {
        display: block;
        margin: 8rem auto;
        width: 265px;
      }

      ul {
        list-style-type: none;
        li {
          svg {
            margin-right: 1rem;
            display: inline-block;
          }
          margin-bottom: 3.2rem;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          text-transform: capitalize;
          color: #ffffff;
        }
      }
    }
  }

  section.featured {
    padding: 1.6rem;
    margin-top: 8rem;
    margin-bottom: 4rem;
    position: relative;
    z-index: 1;
    h3 {
      font-weight: 700;
      font-size: 32px;
      line-height: 56px;
      color: #272343;
      text-transform: capitalize;
      text-align: center;
    }
    img {
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }

  section.how {
    padding: 1.6rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    position: relative;
    z-index: 1;

    & > h3 {
      position: relative;
      text-align: center;
      font-weight: 700;
      font-size: 26px;
      line-height: 40px;
      margin-bottom: 1rem;
      text-transform: capitalize;
      background: linear-gradient(
        92.11deg,
        #7165e3 48.23%,
        rgba(251, 215, 0, 0) 159.7%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    & > em {
      position: relative;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d334a;
      display: block;
      max-width: 718px;
      margin: 0 auto;
      margin-bottom: 7.2rem;
    }
    .content {
      position: relative;

      .box {
        width: 290px;
        height: 250px;
        background: #ffffff;
        border: 1px solid rgba(210, 210, 210, 0.5);
        box-shadow: 0px 2px 8px rgba(210, 210, 210, 0.25);
        border-radius: 24px;
        margin-left: auto;
        margin-right: auto;

        &:not(:last-child) {
          margin-bottom: 4rem;
        }
        img {
          width: 288px;
          display: block;
          border-top-left-radius: 24px;
          border-top-right-radius: 24px;
        }
        h5 {
          padding-left: 2rem;
          padding-right: 2rem;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          color: #272343;
          margin-bottom: 0.8rem;
          margin-top: 2rem;
        }
        em {
          padding-left: 2rem;
          padding-right: 2rem;
          display: block;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #8b8b8b;
        }
      }
    }
  }

  section.app {
    margin-top: 8rem;
    margin-bottom: 4rem;
    position: relative;
    background: #e7aa07;
    padding: 6rem 0;

    & > img {
      width: 90vw;
      display: block;
      margin: 0 auto;
    }

    div.wrapper {
      max-width: 1200px;
      margin: 0 auto;
      padding: 6rem 1.6rem;

      display: inline-block;
      h3 {
        font-weight: 700;
        font-size: 22px;
        line-height: 36px;
        text-transform: capitalize;
        color: #ffffff;
        margin-bottom: 2.4rem;
        span {
          display: block;
        }
      }
      & > a {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-decoration: underline;
        color: #272343;
        display: block;
        margin-bottom: 3rem;
      }
      .buttons {
        display: flex;
        margin-bottom: 6rem;
        a {
          text-decoration: none;
          display: flex;
          align-items: center;
          text-align: center;
          img {
            margin-right: 1rem;
            width: 1.4rem;
          }

          background: #000000;
          border-radius: 54px;
          border: none;
          padding: 1.4rem 2rem;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          text-transform: capitalize;
          color: #ffffff;
          &:not(:last-child) {
            margin-right: 2rem;
          }
        }
      }
    }
  }

  section.transactions {
    padding: 1.6rem;
    margin: 8rem auto;
    position: relative;
    z-index: 1;

    & > h3 {
      position: relative;
      text-align: center;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 1.6rem;
      text-transform: capitalize;
      color: #272343;
    }
    div.flags {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 1.5rem;
      .flag {
        width: 60px;
        margin-left: auto;
        margin-right: auto;
        // cursor: pointer;
        padding: 1rem;
        border-radius: 1rem;
        background-color: rgb(241, 241, 241);
        img {
          width: 100%;
        }
      }
      img {
        width: 60px;
      }
    }
  }

  section.testimonials {
    padding: 1.6rem;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 8rem;
    margin-bottom: 8rem;
    position: relative;
    z-index: 1;

    & > h3 {
      position: relative;
      text-align: center;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 1.6rem;
    }
    & > em {
      position: relative;
      font-family: "Lato";
      font-style: normal;
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #2d334a;
      margin-bottom: 7.2rem;
    }
    .boxes {
      .testimonial {
        background: #ffffff;
        border: 1px solid rgba(210, 210, 210, 0.5);
        box-shadow: 0px 2px 8px rgba(210, 210, 210, 0.25);
        border-radius: 14px;
        padding: 1.4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;

        &:not(:last-child) {
          margin-bottom: 4rem;
        }
        &:first-child {
          .initials {
            background: #e7aa07;
          }
        }
        &:nth-child(2) {
          .initials {
            background: #7165e3;
          }
        }
        &:last-child {
          .initials {
            background: #68d585;
          }
        }
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #2d334a;
          margin-bottom: 2rem;
        }
        div.name {
          display: flex;
          align-items: center;
          .initials {
            height: 50px;
            width: 50px;
            color: #fff;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            text-transform: capitalize;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 2.4rem;
          }
          em {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            text-transform: capitalize;
            color: #11202a;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 400px) {
  .home-container {
    section.transactions {
      div.flags {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}

@media screen and (min-width: 480px) {
  .home-container {
    section.transactions {
      div.flags {
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }
}

@media screen and (min-width: 560px) {
  .home-container {
    section.transactions {
      div.flags {
        grid-template-columns: repeat(7, 1fr);
      }
    }
  }
}
@media screen and (min-width: 640px) {
  .home-container {
    section.transactions {
      div.flags {
        grid-template-columns: repeat(8, 1fr);
      }
    }
  }
}
@media screen and (min-width: 720px) {
  .home-container {
    section.transactions {
      div.flags {
        grid-template-columns: repeat(9, 1fr);
      }
    }
  }
}
@media screen and (min-width: 800px) {
  .home-container {
    section.transactions {
      div.flags {
        grid-template-columns: repeat(10, 1fr);
        margin-top: 4rem;
        max-width: 880px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media screen and (min-width: 500px) {
  .home-container {
    section.hero {
      div.left {
        & > h1 {
          font-size: 40px;
          margin-top: 4rem;
        }
        & > p {
          font-size: 18px;
        }
        .buttons button {
          font-size: 18px;
          width: 180px;
        }
      }
      & > div.right {
        img {
          width: 320px;
        }
        div.text {
          h3 {
            font-size: 16px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
    section.testimonials {
      width: 100%;
      div.boxes {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        .testimonial {
          width: 300px;
          height: 222px;
          margin: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .home-container {
    section.needs {
      h3 {
        font-size: 30px;
      }
      em {
        font-size: 20px;
      }
      .tabs {
        button {
          font-size: 16px;
        }
      }
    }
    section.security {
      h3 {
        font-size: 36px;
        line-height: 40px;
      }
      em {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
      .boxes {
        .box {
          h5 {
            font-size: 28px;
          }
          p {
            font-size: 22px;
          }
        }
      }
    }
    section.features {
      div.content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          display: block;
          width: 45%;
          max-width: 300px;
        }
        ul {
          width: 45%;
        }
      }
    }
  }
}

@media screen and (min-width: 650px) {
  .home-container {
    section.how {
      h3 {
        font-size: 30px;
      }
      em {
        font-size: 20px;
      }
      .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .box {
          margin: 0;
          margin-right: 3rem;
        }
      }
    }
    section.app {
      display: flex;
      align-items: center;
      div.wrapper {
        h3 {
          font-size: 26px;
        }
        a {
          font-size: 20px;
        }
      }
      img {
        width: 50%;
        max-width: 480px;
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .home-container {
    section.hero {
      padding: 3.2rem;
      display: flex;
      & > button {
        display: none;
      }
      div.left {
        margin-right: 4rem;
        width: 50%;
        h1 {
          font-size: 46px;
          margin-bottom: 3rem;
        }
        p {
          font-size: 22px;
          margin-bottom: 3rem;
        }
      }
    }

    section.impact {
      padding: 3.2rem;
      display: flex;
      justify-content: center;

      h3 {
        width: 100px;
        text-align: left;
        margin-right: 8rem;
      }
      .wrapper {
        display: block;
        border: none;
        margin: 0;
        padding: 0;
        .impact-item {
          width: unset;
          text-align: left;
          &:not(:last-child) {
            margin-right: 2rem;
          }
          h3 {
            margin: 0;
            font-size: 30px;
            margin-bottom: 0.8rem;
          }
          em {
            font-size: 18px;
          }
          &::after {
            display: none;
          }
        }
      }
    }

    section.needs {
      max-width: 768px;
      .tabs {
        margin-left: auto;
        margin-right: auto;
        width: 768px;
      }
    }

    section.security {
      margin-top: 8rem;
      margin-left: auto;
      margin-right: auto;
      em {
        width: 480px;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      .boxes {
        display: flex;
        .box {
          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .home-container {
    section.hero {
      div.right {
        width: 400px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .home-container {
    section.hero {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      padding: 2.8rem;
      padding-top: 12rem;
      div.left {
        h1 {
          font-style: normal;
          font-weight: 700;
          font-size: 60px;
          line-height: 77px;
          margin-bottom: 2.4rem;
          margin-top: 0;
          span {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              height: 28px;
              width: 105%;
              bottom: 6px;
              left: 15px;
              background: #68d585;
              z-index: -1;
            }
          }
        }
        p {
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
          margin-bottom: 6rem;
        }
        .buttons {
          display: flex;
          justify-content: space-between;
          button {
            font-weight: 600;
            font-size: 19px;
            line-height: 37px;
            text-align: center;
            padding: 1.4rem;
            svg {
              margin-left: 1rem;
            }
            &:first-child {
              color: #fff;
              background: #e7aa07;
              border-radius: 40px;
              border: none;
              margin-right: 40px;
              width: 40%;
            }
            &:last-child {
              display: inline-block;
              width: 50%;
              color: #272343;
              border: 2px solid #272343;
              border-radius: 51px;
              background-color: #fff;
            }
          }
        }
      }
      div.right {
        width: 48%;
        position: relative;
        overflow: hidden;

        display: flex;
        justify-content: center;
        align-items: flex-end;
        img {
          position: relative;
          left: 15px;
          width: 340px;
        }
        div.text {
          padding: 1.6rem;
          position: absolute;
          background: linear-gradient(
            360deg,
            rgba(255, 255, 255, 0.6) -33.52%,
            rgba(253, 253, 253, 0.2) 294.89%
          );
          box-shadow: 0px -1px 8px rgba(198, 198, 198, 0.25);
          backdrop-filter: blur(8px);
          width: 100%;
          h3 {
            font-weight: 700;
            font-size: 18px;
            line-height: 23px;
            color: #11202a;
          }
          p {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #2c353b;
          }
        }
      }
    }
    section.impact {
      padding: 2.8rem;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;

      width: 100%;
      & > h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 56px;
        text-transform: capitalize;
        color: #272343;
        width: 195px;
        display: inline-block;
        margin-right: 12rem;
      }
      .wrapper {
        padding: 2.8rem;
        max-width: 1200px;
        margin: 0 auto;

        .impact-item {
          display: inline-block;
          &:not(:last-child) {
            margin-right: 12rem;
          }

          h3 {
            font-weight: 800;
            font-size: 60px;
            line-height: 56px;
            text-transform: capitalize;
            color: #272343;
          }
          em {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 56px;
            text-transform: capitalize;
            color: #2d334a;
          }
        }
      }
    }
    section.needs {
      padding: 2.8rem;
      max-width: 1200px;
      margin: 0 auto;
      margin-top: 8rem;
      margin-bottom: 4rem;
      position: relative;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.4;
        background-image: url("./../../assets/images/home_what_do_you_need_bg.png");
        z-index: 0;
      }

      h3 {
        position: relative;
        text-align: center;
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 2.4rem;
        text-transform: capitalize;
        background: linear-gradient(
          92.11deg,
          #7165e3 48.23%,
          rgba(251, 215, 0, 0) 159.7%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
      em {
        position: relative;
        font-family: "Lato";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #2d334a;
        display: block;
        max-width: 718px;
        margin: 0 auto;
        margin-bottom: 7.2rem;
      }
      .tabs {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.6rem;
        border: 1px solid #d2d2d2;
        border-radius: 14px;
        margin-bottom: 3.2rem;
        width: unset;
        button {
          font-family: "Plus Jakarta Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 56px;
          text-transform: capitalize;
          color: #2d334a;
          background-color: transparent;
          border: none;
          &.active {
            color: #fff;
            background: #e7aa07;
            border-radius: 47px;
            padding: 0.8rem 2.4rem;
          }
        }
      }
    }
    section.security {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 550px;
      padding: 2.8rem;
      max-width: 1200px;
      margin: 8rem auto 4rem;
      position: relative;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.6;
        background-image: url("./../../assets/images/checked_purple_bg.png");
        z-index: 0;
      }

      // h3 {
      //   // display: none;
      // }

      em {
        position: relative;
        font-family: "Lato";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #2d334a;
        display: block;
        max-width: 718px;
        margin: 0 auto;
        margin-bottom: 7.2rem;
        width: unset;
      }

      div.boxes {
        text-align: left;
        .box {
          &:not(:last-child) {
            margin-right: 6rem;
          }
          &:first-child h5 {
            color: #7165e3;
          }
          &:nth-child(2) h5 {
            color: #e7aa07;
          }
          &:last-child h5 {
            color: #68d585;
          }
          width: 340px;
          display: inline-block;
          h5 {
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 1.6rem;
          }

          p {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: #2d334a;
          }
        }
      }
    }
    section.features {
      padding: 2.8rem;
      max-width: 1200px;
      margin: 0 auto;
      margin-top: 4rem;
      margin-bottom: 4rem;
      position: relative;
      z-index: 1;

      h3 {
        position: relative;
        text-align: center;
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 2.4rem;
        text-transform: capitalize;
        background: linear-gradient(
          92.11deg,
          #7165e3 48.23%,
          rgba(251, 215, 0, 0) 159.7%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        display: block;
      }
      em {
        display: block;
        position: relative;
        font-family: "Lato";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #2d334a;
        display: block;
        max-width: 718px;
        margin: 0 auto;
        margin-bottom: 7.2rem;
      }
      div.content {
        position: relative;
        background: #272343;
        border-radius: 38px;
        display: flex;
        align-items: center;
        padding: 8rem;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          opacity: 0.6;
          background-image: url("./../../assets/images/checked_purple_bg.png");
          z-index: 0;
        }
        div.left {
          margin-right: 7rem;
        }
        div.right {
          ul {
            list-style-type: none;
            li {
              svg {
                margin-right: 2.6rem;
                display: inline-block;
              }
              margin-bottom: 3.2rem;
              font-weight: 600;
              font-size: 24px;
              line-height: 30px;
              text-transform: capitalize;
              color: #ffffff;
            }
          }
        }
      }
    }
    section.featured {
      padding: 2.8rem;
      max-width: 1200px;
      margin: 0 auto;
      margin-top: 8rem;
      margin-bottom: 4rem;
      position: relative;
      z-index: 1;
      h3 {
        font-weight: 700;
        font-size: 40px;
        line-height: 56px;
        color: #272343;
        text-transform: capitalize;
        text-align: center;
      }
      img {
        width: 1120px;
        margin: 0 auto;
        display: block;
      }
    }
    section.how {
      padding: 2.8rem;
      max-width: 1200px;
      margin: 0 auto;
      margin-top: 8rem;
      margin-bottom: 18rem;
      position: relative;
      z-index: 1;

      & > h3 {
        position: relative;
        text-align: center;
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 2.4rem;
        text-transform: capitalize;
        background: linear-gradient(
          92.11deg,
          #7165e3 48.23%,
          rgba(251, 215, 0, 0) 159.7%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
      & > em {
        position: relative;
        font-family: "Lato";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #2d334a;
        display: block;
        max-width: 718px;
        margin: 0 auto;
        margin-bottom: 7.2rem;
      }
      .content {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        .box {
          width: 352px;
          height: 296px;
          background: #ffffff;
          border: 1px solid rgba(210, 210, 210, 0.5);
          box-shadow: 0px 2px 8px rgba(210, 210, 210, 0.25);
          border-radius: 24px;
          &:nth-child(2) {
            position: relative;
            top: 80px;
          }
          img {
            width: 350px;
            display: block;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
          }
          h5 {
            padding-left: 4.2rem;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            color: #272343;
            margin-bottom: 0.8rem;
            margin-top: 2rem;
          }
          em {
            padding-left: 4.2rem;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #8b8b8b;
          }
        }
      }
    }
    section.app {
      margin-top: 8rem;
      margin-bottom: 4rem;
      position: relative;
      background: #e7aa07;
      display: flex;

      div.wrapper {
        max-width: 1200px;
        margin: 0 auto;

        padding: 10rem 0;
        margin-right: 4rem;
        width: 545px;
        h3 {
          width: 512px;
          font-weight: 700;
          font-size: 40px;
          line-height: 59px;
          text-transform: capitalize;
          color: #ffffff;
          margin-bottom: 2.4rem;
          span {
            display: block;
          }
        }
        & > a {
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          text-decoration: underline;
          color: #272343;
          display: block;
          margin-bottom: 5.6rem;
        }
        .buttons {
          a {
            text-decoration: none;
            // display: inline-block;
            text-align: center;
            justify-content: space-evenly;
            img {
              margin-right: 1rem;
              width: 5rem;
            }
            span {
              position: relative;
              bottom: 0px;
            }
            width: 256px;
            background: #000000;
            border-radius: 54px;
            border: none;
            padding: 1.4rem 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            text-transform: capitalize;
            color: #ffffff;
            &:not(:last-child) {
              margin-right: 3.2rem;
            }
          }
        }

        img {
          width: 516px;
        }
      }
    }
    section.transactions {
      padding: 2.8rem;
      max-width: 1200px;
      margin: 10rem auto 15rem;
      position: relative;
      z-index: 1;

      & > h3 {
        position: relative;
        text-align: center;
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 3.2rem;
        text-transform: capitalize;
        color: #272343;
      }
    }
    section.testimonials {
      padding: 2.8rem;
      max-width: 1200px;
      margin: 0 auto;
      margin-top: 8rem;
      margin-bottom: 8rem;
      position: relative;
      z-index: 1;

      & > h3 {
        position: relative;
        text-align: center;
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        text-transform: capitalize;
        color: #272343;
        margin-bottom: 1.6rem;
      }
      & > em {
        position: relative;
        font-family: "Lato";
        font-style: normal;
        display: block;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #2d334a;
        margin-bottom: 7.2rem;
      }
      div.boxes {
        display: flex;
        justify-content: space-between;
        .testimonial {
          background: #ffffff;
          border: 1px solid rgba(210, 210, 210, 0.5);
          box-shadow: 0px 2px 8px rgba(210, 210, 210, 0.25);
          border-radius: 14px;
          padding: 1.4rem;
          width: 352px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &:first-child {
            .initials {
              background: #e7aa07;
            }
          }
          &:nth-child(2) {
            .initials {
              background: #7165e3;
            }
          }
          &:last-child {
            .initials {
              background: #68d585;
            }
          }
          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #2d334a;
            margin-bottom: 2rem;
          }
          div.name {
            display: flex;
            align-items: center;
            .initials {
              height: 50px;
              width: 50px;
              color: #fff;
              font-weight: 700;
              font-size: 24px;
              line-height: 30px;
              text-transform: capitalize;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 2.4rem;
            }
            em {
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 25px;
              text-transform: capitalize;
              color: #11202a;
            }
          }
        }
      }
    }
  }
}
