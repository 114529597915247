.about-container {
  section.features {
    padding: 5rem 1.6rem;
    .feature {
      margin-bottom: 8rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 550px;
      h3 {
        font-weight: 600;
        font-size: 35px;
        line-height: 54px;
        text-transform: capitalize;
        color: #272343;
      }
      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 35px;
        color: #2d334a;
        margin-top: 2rem;
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .about-container {
    section.features {
      max-width: 950px;
      margin-left: auto;
      margin-right: auto;

      padding-left: 3.2rem;
      padding-right: 3.2rem;
      .feature {
        &:nth-child(odd) {
          margin-left: 0;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
        .content {
          h3 {
            font-size: 40px;
          }
          li {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .about-container {
    section.features {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      .feature {
        margin-bottom: 15rem;
        position: relative;
        z-index: 1;
        max-width: unset;
        &:nth-child(odd) .content {
          margin-left: 0;
        }
        &:nth-child(even) .content {
          margin-right: 0;
        }

        .content {
          max-width: 700px;
          margin-left: auto;
          margin-right: auto;

          h3 {
            font-weight: 700;
            font-size: 60px;
            line-height: 88px;
          }
          p {
            font-weight: 500;
            font-size: 24px;
            line-height: 54px;
          }
        }

        &:nth-child(odd) {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0.1;
            background-image: url("./../../assets/svg/about_desktop_feature_bg.svg");
            z-index: -1;
          }
        }

        &:nth-child(even) {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0.1;
            background-image: url("./../../assets/svg/about_desktop_feature_bg.svg");
            z-index: -1;
          }
        }
      }
    }
  }
}
