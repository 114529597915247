.main-DashHeader {
  // max-width: 1280px;
  // margin: 0 auto;
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0rem;
  & > ul {
    list-style-type: none;
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      display: inline-block;
      position: relative;
      ul {
        display: none;
        &.active {
          max-height: 50rem;
          position: absolute;
          top: 30px;
          left: 0;
          z-index: 3;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(5, 1fr);
          width: 65rem;
          height: 35rem;
          border-radius: 10px;
          overflow: hidden;
          transition: max-height ease-in-out 0.5s;
          background-color: #272343;
          gap: 2rem;
          padding: 3rem;
        }
        li {
          padding: 0.5rem 1.6rem;
          a {
            color: #fff;
          }
          &:first-child {
            margin-top: 0rem;
          }
          &:last-child {
            margin-bottom: 2rem;
          }
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
          :hover {
            text-decoration: underline;
            text-decoration-color: #e7aa07;
          }
        }
      }
      a {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #272343;
        position: relative;
        text-decoration: none;
        top: -5px;
        button {
          outline: none;
        }
        &.active {
          &::after {
            content: "";
            position: absolute;
            height: 3px;
            width: 120%;
            background-color: #e7aa07;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
  
  div.left {
    flex: 0 0 25%;
    border-right: 1px solid #d2d2d2;
    padding-left: 2rem;
  }
  div.right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2.5rem;
    h3 {
      color: #272343;
      font: 800 25px 'plus jakarta sans';
      line-height: 31px;
    }
    .split {
      flex: 0 0 37%;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 1.5rem;
      img {
        width: unset;
        height: unset;
      }
      .switch {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem;
        background-color: #f5f5f5;
        border-radius: 1rem;
        cursor: pointer;
      }
      .acct {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        > img:first-child {
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }
        p {
          font: 600 15px 'plus jakarta sans';
          color: #272343;
        }
      }
    }
  }



}

nav.desktop-nav {
  display: none;
}

nav.mobile-nav {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 1.6rem;
  position: fixed;
  z-index: 3;
  width: 100%;
  top: 0;

  img {
    width: 100px;
  }
  .buttons {
    display: flex;
    align-items: center;
    a {
      background: #e7aa07;
      border-radius: 43px;
      border: none;
      color: #fff;
      font-weight: 800;
      font-size: 12px;
      line-height: 18px;
      padding: 0.8rem;
      margin-right: 2rem;
      width: 125px;
      text-align: center;
    }
    button {
      width: 18px;
      height: 18px;
      img {
        width: 18px;
        height: 18px;
      }
      background: transparent;
      border: none;
    }
  }
  div.menu {
    overflow-y: auto;
    position: absolute;
    padding: 1.6rem;
    // padding-top: 4rem;
    // padding-left: 3rem;
    top: 0;
    left: -15px;
    background-color: #fff;
    width: 280px;
    height: 135vh;
    z-index: 3;
    transition: transform ease-in-out 0.5s;
    transform: translateX(-280px);
    &.active {
      transform: translateX(0);
    }
    & > button {
      position: absolute;
      right: 20px;
      top: 10px;
      background-color: transparent;
      font-size: 2.4rem;
      border: none;
    }
    ul {
      list-style-type: none;
      &.submenu {
        padding-left: 1.6rem;
        max-height: 0;
        overflow: hidden;
        transition: max-height ease-in-out 0.5s;
        &.active {
          max-height: 75rem;
        }
      }
      li {
        margin-bottom: 3rem;
        display: block;
        a {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #272343;
          text-decoration: none;
        }
        button {
          background-color: transparent;
          border: none;
          margin-left: 1rem;
        }
      }
    }
  }
}

@media screen and (min-width: 900px) {
  nav.mobile-nav {
    display: none;
  }
  nav.desktop-nav {
    display: block;
    .main-DashHeader {
      img {
        width: 120px;
      }
      ul li a button {
        background-color: transparent;
        border: none;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  nav.desktop-nav {
    .main-DashHeader {
      img {
        width: unset;
      }
      ul li a button {
        margin-left: 1rem;
        display: inline-block;
      }
    }
  }
}


