.price-container {
  padding: 4rem 1.6rem;
  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    text-transform: capitalize;
    color: #272343;
    margin-bottom: 0.8rem;
  }
  & > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #2d334a;
    margin-bottom: 3.2rem;
  }

  .plans {
    .plan {
      padding: 4rem 2rem;
      border: 1px solid #d2d2d2;
      border-radius: 20px;
      &.special {
        border: 2px solid #e7aa07;
        & > .top > em {
          color: #e7aa07;
          background: rgba(231, 170, 7, 0.05);
          border: 0.5px solid rgba(231, 170, 7, 0.5);
        }
        & > em {
          color: #e7aa07;
        }
        & > button {
          width: 100%;
          border: none;
          background: #e7aa07;
          border-radius: 5px;
          padding: 1rem;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #ffffff;
          margin-top: 3rem;
        }
      }
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.6rem;
        height: 33px;
        h3 {
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #272343;
        }
        em {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: -0.01em;
          color: #8b8b8b;
          display: block;
          padding: 0.8rem 1.6rem;
          background: rgba(210, 210, 210, 0.2);
          border-radius: 22px;
        }
      }
      & > em {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #272343;
        display: block;
        margin-bottom: 2rem;

        span {
          font-size: 36px;
          line-height: 45px;
        }
      }
      & > p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2d334a;
        text-align: left;
        margin-bottom: 2rem;
      }
      ul {
        list-style-type: none;
        li {
          display: flex;
          align-items: flex-start;
          margin-bottom: 2rem;
          svg {
            color: #e7aa07;
            font-size: 1.6rem;
            display: block;
            margin-right: 1.5rem;
          }
          em {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #2d334a;
            display: block;
            margin-bottom: 0.8rem;
          }
          p {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #8b8b8b;
          }
        }
      }
      & > button {
        width: 100%;
        border: none;
        border-radius: 5px;
        padding: 1rem;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #e7aa07;
        background: #fdf7e6;
        margin-top: 3rem;
      }
    }
  }
}

@media screen and (min-width: 650px) {
  .price-container {
    .plans {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .plan {
        width: 300px;
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .price-container {
    .plans {
      .plan {
        width: 400px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .price-container {
    max-width: 1200px;
    padding: 3.2rem;
    margin: 4rem auto 10rem;

    & > p {
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;
    }
    .plans {
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: flex-start;
      .plan {
        width: 350px;
        & > p {
          width: 275px;
        }
        ul li div.text p {
          max-height: 0;
          overflow: hidden;
          transition: max-height ease-in-out 0.5s;
        }
        &:hover {
          ul li div.text p {
            max-height: 100px;
          }
        }
      }
    }
  }
}
