@import "./../../utils/scss/mixins.scss";

.signup-container {
  text-align: center;
  padding: 4rem 1.6rem;
  @include error_text_style;
  .mobile-progress {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    .circle {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #e0e0e0;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.01em;
      color: #d2d2d2;
      margin: 0 2.5px;
      &.ongoing {
        border: 2px solid #e7aa07;
        color: #2d334a;
      }
      &.done {
        background-color: #e7aa07;
        color: #fff;
        border: none;
        font-weight: bold;
        font-size: 1.6rem;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .separator {
      flex: 1;
      height: 2px;
      background-color: #d2d2d2;
      &.active {
        background-color: #e7aa07;
      }
    }
  }
  .desktop-progress {
    display: none;
  }
  main > button {
    width: 100%;
    padding: 1.5rem;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #fffffe;
    background: #e7aa07;
    border-radius: 10px;
    border: none;
    margin: 3rem 0;
  }
  main > em {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #8b8b8b;
    a {
      color: rgba(45, 51, 74, 1);
      text-decoration: none;
      font-family: "Lato";
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
    }
  }
  .signup-first-page,
  .signup-second-page,
  .signup-third-page,
  .signup-fourth-page {
    h1 {
      font-weight: 600;
      font-size: 40px;
      line-height: 51px;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 1rem;
    }
    em {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #2d334a;
      display: block;
      margin-bottom: 4.4rem;
    }

    & > button {
      width: 100%;
      border: 2px solid #d2d2d2;
      border-radius: 8px;
      background-color: #fff;
      padding: 1.5rem;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #2d334a;
      margin-bottom: 3.2rem;
      img {
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 0.8rem;
        position: relative;
        top: 2px;
      }
    }

    form {
      text-align: left;
      label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #2d334a;
        margin-bottom: 1.6rem;
        display: block;

        a {
          color: #2d334a;
        }

        span {
          color: rgba(255, 81, 65, 1);
        }

        textarea,
        input,
        select {
          resize: none;
          display: block;
          padding: 1rem;
          border: 2px solid #d2d2d2;
          border-radius: 8px;
          margin-top: 0.8rem;
          width: 100%;
          &::placeholder {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #d2d2d2;
          }
        }

        input[type="checkbox"] {
          display: inline-block;
          margin: 0;
          margin-left: 1rem;
          width: unset;
          position: relative;
          top: 2px;
        }

        .input-container {
          display: flex;
          align-items: center;
          border: 2px solid #d2d2d2;
          border-radius: 8px;
          margin-top: 0.8rem;
          padding: 1rem;
          width: 100%;
          select {
            border: none;
            border-radius: 8px;
          }
          input {
            padding: 0;
            border: none;
            margin: 0;
            outline: none;
            margin-left: 0.5rem;
            border-radius: 0;
          }
        }
      }
      & > button {
        width: 100%;
        padding: 1.5rem;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #fffffe;
        background: #e7aa07;
        border-radius: 10px;
        border: none;
        margin-top: 3rem;
      }
    }

    .verification {
      margin-top: 4rem;
      & > img {
        width: 262px;
        margin-bottom: 3.2rem;
        display: block;
      }
      em {
        img {
          width: 24px;
          height: 24px;
          margin-right: 0.8rem;
        }
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-transform: capitalize;
        color: #8b8b8b;
      }
    }
  }
}

@media screen and (min-width: 650px) {
  .signup-container {
    main > button {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
  .signup-first-page,
  .signup-second-page,
  .signup-third-page,
  .signup-fourth-page {
    & > button {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    form {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
}

@media screen and (min-width: 1200px) {
  .signup-container {
    padding: 0;
    display: flex;
    .mobile-progress {
      display: none;
    }
    .desktop-progress {
      display: block;
      width: 600px;
      background: #f9f9f9;
      padding: 8rem 5rem;
      .progress-box {
        display: flex;
        margin-bottom: 5.4rem;
        cursor: pointer;

        &.active {
          .check {
            color: #e7aa07;
            border: solid 2px #e7aa07;
          }
          .text {
            h3 {
              color: #272343;
            }
            em {
              color: #8b8b8b;
            }
          }
        }

        .check {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          margin-right: 1.8rem;
          color: #d2d2d2;
          border: solid 2px #d2d2d2;
        }
        .text {
          text-align: left;
          h3 {
            font-weight: 600;
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 0.5rem;
            color: #8b8b8b;
          }
          em {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #d2d2d2;
          }
        }
      }
    }
    main {
      width: 45%;
      max-width: 600px;
      padding: 8rem 0;
      margin-left: auto;
      margin-right: auto;

      .signup-first-page,
      .signup-second-page {
        form {
          max-width: 600px;
          .passwords,
          .country,
          .codes,
          .names {
            display: flex;
            justify-content: space-between;
            label {
              width: 48%;
              input {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
