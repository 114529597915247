.main-header {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding: 2.8rem;
  & > ul {
    list-style-type: none;
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      display: inline-block;
      position: relative;
      ul {
        position: absolute;
        top: 30px;
        left: 0;
        background-color: #fff;
        z-index: 3;
        display: flex;
        flex-direction: column;
        width: 200px;
        border-radius: 10px;
        overflow: hidden;
        max-height: 0;
        transition: max-height ease-in-out 0.5s;
        background-color: #272343;
        //border: solid 1px #e7aa07;
        &.active {
          max-height: 190px;
        }
        li {
          padding: 0.5rem 1.6rem;
          a {
            color: #fff;
          }
          &:first-child {
            margin-top: 2rem;
          }
          &:last-child {
            margin-bottom: 2rem;
          }
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
      a {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #272343;
        position: relative;
        text-decoration: none;
        top: -5px;
        button {
          outline: none;
        }
        &.active {
          &::after {
            content: "";
            position: absolute;
            height: 3px;
            width: 120%;
            background-color: #e7aa07;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
  div.buttons {
    a {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: #272343;
      border-radius: 30px;
      &:first-child {
        background-color: transparent;
        padding: 1.6rem 2.5rem;
        margin-right: 2.4rem;
        border: 2px solid #272343;
        border-radius: 31px;
      }
      &:last-child {
        background-color: transparent;
        padding: 1.6rem 2.5rem;
        background: linear-gradient(
          269.45deg,
          #e7aa07 -11.93%,
          #f178cf 112.72%,
          #7165e3 163.24%
        );
        border-radius: 43px;
        border: none;
        color: white;
      }
    }
  }
}

nav.desktop-nav {
  display: none;
}

nav.mobile-nav {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 1.6rem;
  position: fixed;
  z-index: 3;
  width: 100%;
  top: 0;

  img {
    width: 100px;
  }
  .buttons {
    display: flex;
    align-items: center;
    a {
      background: #e7aa07;
      border-radius: 43px;
      border: none;
      color: #fff;
      font-weight: 800;
      font-size: 12px;
      line-height: 18px;
      padding: 0.8rem;
      margin-right: 2rem;
      width: 125px;
      text-align: center;
    }
    button {
      width: 18px;
      height: 18px;
      img {
        width: 18px;
        height: 18px;
      }
      background: transparent;
      border: none;
    }
  }
  div.menu {
    overflow-y: auto;
    position: absolute;
    padding: 1.6rem;
    padding-top: 4rem;
    padding-left: 3rem;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 280px;
    height: 100vh;
    z-index: 3;
    transition: transform ease-in-out 0.5s;
    transform: translateX(-280px);
    &.active {
      transform: translateX(0);
    }
    & > button {
      position: absolute;
      right: 20px;
      top: 10px;
      background-color: transparent;
      font-size: 2.4rem;
      border: none;
    }
    ul {
      list-style-type: none;
      &.submenu {
        padding-left: 1.6rem;
        max-height: 0;
        overflow: hidden;
        transition: max-height ease-in-out 0.5s;
        &.active {
          max-height: 250px;
        }
      }
      li {
        margin-bottom: 3rem;
        display: block;
        a {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #272343;
          text-decoration: none;
        }
        button {
          background-color: transparent;
          border: none;
          margin-left: 1rem;
        }
      }
    }
  }
}

@media screen and (min-width: 900px) {
  nav.mobile-nav {
    display: none;
  }
  nav.desktop-nav {
    display: block;
    .main-header {
      img {
        width: 120px;
      }
      ul li a button {
        background-color: transparent;
        border: none;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  nav.desktop-nav {
    .main-header {
      img {
        width: unset;
      }
      ul li a button {
        margin-left: 1rem;
        display: inline-block;
      }
    }
  }
}
