.contact-container {
  & > img {
    width: 100%;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  h1,
  form {
    padding: 0 1.6rem;
  }

  h1 {
    padding-top: 1rem;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    text-transform: capitalize;
    color: #272343;
    margin-bottom: 1rem;
  }
  & > .wrapper > em {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #2d334a;
    font-style: normal;
    margin-bottom: 2rem;
    display: block;
    padding: 0 1.6rem;
  }
  form {
    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #2d334a;
      margin-bottom: 1.6rem;
      display: block;

      span {
        color: rgba(255, 81, 65, 1);
      }

      textarea,
      input {
        resize: none;
        display: block;
        padding: 1rem;
        border: 2px solid #d2d2d2;
        border-radius: 8px;
        margin-top: 0.8rem;
        width: 100%;
        &::placeholder {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #d2d2d2;
        }
      }

      .input-container {
        display: flex;
        align-items: center;
        border: 2px solid #d2d2d2;
        border-radius: 8px;
        margin-top: 0.8rem;
        padding: 1rem;
        width: 100%;
        select {
          border: none;
          border-radius: 8px;
        }
        input {
          padding: 0;
          border: none;
          margin: 0;
          outline: none;
          margin-left: 0.5rem;
          border-radius: 0;
        }
      }
    }
    .captcha {
      display: flex;
      align-items: center;
      margin: 2rem 0;
      img {
        width: 50%;
        display: inline-block;
        max-width: 250px;
        margin-right: 3.2rem;
      }
      button {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #272343;
        border: none;
        background: #f7f5ff;
        border-radius: 29px;
        padding: 1rem 0.5rem;
        width: 110px;
        svg {
          margin-right: 0.5rem;
          display: inline-block;
        }
      }
    }
    & > button {
      background: #e7aa07;
      border-radius: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      width: 100%;
      border: none;
      padding: 1rem;
    }
  }

  .location,
  .email {
    background: #f9f9f9;
    border-radius: 14px;
    margin: 5rem 1.6rem;
    padding: 2rem 1.6rem;
    .icon {
      width: 58px;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e7aa07;
      border-radius: 7px;
      margin-bottom: 2.4rem;
      svg {
        height: 28px;
        width: 22px;
        color: #fff;
      }
    }
    em {
      display: block;
      &:nth-child(2) {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        color: #272343;
        font-style: normal;
        margin-bottom: 1rem;
      }
      &:nth-child(3) {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #8b8b8b;
        margin-bottom: 1.5rem;
      }
      &:nth-child(4) {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.01em;

        color: #2d334a;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .contact-container {
    form {
      .top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        column-gap: 1rem;
      }
    }
    .info {
      display: flex;
    }
  }
}

@media screen and (min-width: 900px) {
  .contact-container {
    .info {
      .location {
        margin-right: 3.2rem;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .contact-container {
    max-width: 1300px;
    margin: 10rem auto;

    display: grid;
    grid-template-areas:
      "form img"
      "info info";
    .wrapper {
      grid-area: form;
    }
    img {
      grid-area: img;
    }
    .info {
      grid-area: info;
      .location,
      .email {
        width: 540px;
      }
    }
  }
}
