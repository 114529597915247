footer {
  padding: 1.6rem;
  padding-top: 4rem;
  background: #272343;
  color: #fff;

  .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .copy {
    text-align: center;
    border-top: 1px solid #fffffe;
    margin-top: 4rem;

    & > em {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #c7c7c7;
      display: inline-block;
      margin-top: 2.4rem;
      text-align: center;
    }
  }

  div.footer-about,
  .footer-about-wrapped {
    img {
      margin-bottom: 1.6rem;
    }
    p {
      width: 250px;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.01em;
      margin-bottom: 3.4rem;
    }
    div.socials {
      display: flex;
      a {
        display: inline-block;
        &:not(:last-child) {
          margin-right: 3.2rem;
        }
      }
      img {
        width: 21px;
        height: 21px;
        display: block;
      }
    }
  }

  .footer-about-wrapped {
    display: none;
  }

  div.wrapper {
    display: grid;
    margin: 5rem 0 2rem;

    .services,
    .company,
    .legal {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
      h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 1.6rem;
      }
      ul {
        list-style-type: none;
        li {
          margin-bottom: 0.8rem;
          a {
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            text-transform: capitalize;
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }

  div.location {
    display: flex;
    align-items: flex-start;
    svg {
      display: inline-block;
      margin-right: 1rem;
      width: 14px;
      height: 20px;
      position: relative;
      top: 8px;
    }
    em {
      display: block;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      text-transform: capitalize;
    }
  }

  div.bottom {
    & > a {
      display: flex;
      align-items: flex-start;
      text-decoration: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: #fff;
      width: 100%;
      svg {
        display: inline-block;
        margin-right: 1rem;
        height: 16px;
        width: 20px;
        position: relative;
        top: 8px;
      }
      em {
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
      }
    }
  }

  .fin-1,
  .fin-2 {
    &:first-child {
      margin-bottom: 0.6rem;
    }
    svg {
      display: inline-block;
      margin-right: 1rem;
      width: 15px;
      height: 24px;
      position: relative;
      top: 8px;
    }
    em {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
    }
  }
}

@media screen and (max-width: 500px) {
  footer {
    div.wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
    }
  }
}

@media screen and (min-width: 500px) {
  footer {
    div.wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
    }
  }
}

@media screen and (min-width: 650px) {
  footer {
    div.wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
    }
    div.footer-about {
      text-align: center;
      & > img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      p {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
      }
      div.socials {
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: 800px) {
  footer {
    div.wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
    }
    div.footer-about {
      display: none;
    }
    .footer-about-wrapped {
      display: block;
    }
  }
}

@media screen and (min-width: 1200px) {
  footer {
    .container {
      padding: 2.8rem;
    }

    .copy {
      text-align: center;
      border-top: 1px solid #dedede;

      & > em {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #8b8b8b;
        display: inline-block;
        margin-top: 2.4rem;
        text-align: center;
      }
    }

    div.top {
      display: flex;
      margin-bottom: 5.6rem;
      div.footer-about {
        margin-right: 11rem;
        img {
          margin-bottom: 1.6rem;
        }
        p {
          width: 250px;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.01em;
          color: #2d334a;
          margin-bottom: 3.4rem;
        }
        div.socials {
          display: flex;
          img {
            width: 21px;
            height: 21px;
            display: block;
            margin-right: 3.2rem;
          }
        }
      }
      .services,
      .company,
      .legal {
        &:not(:last-child) {
          margin-right: 11rem;
        }
        h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
          color: #272343;
          margin-bottom: 1.6rem;
        }
        ul {
          list-style-type: none;
          li {
            margin-bottom: 0.8rem;
            a {
              font-weight: 500;
              font-size: 16px;
              line-height: 30px;
              text-transform: capitalize;
              color: #2d334a;
            }
          }
        }
      }
    }

    div.bottom {
      display: flex;
      align-items: center;
      margin-bottom: 6.7rem;
      color: #fff;
      div.location {
        display: flex;
        align-items: flex-start;
        margin-right: 4.5rem;
        svg {
          display: inline-block;
          margin-right: 1.7rem;
          width: 14px;
          height: 20px;
          position: relative;
          top: 8px;
        }
        em {
          display: block;
          width: 306px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 30px;
          text-transform: capitalize;
        }
      }

      a {
        display: flex;
        margin-right: 10.9rem;
        align-items: flex-start;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: #fff;
        width: unset;
        em {
          font-style: normal;
        }
        svg {
          display: inline-block;
          margin-right: 1.7rem;
          height: 16px;
          width: 20px;
          position: relative;
          top: 8px;
        }
      }

      div.fin {
        .fin-1,
        .fin-2 {
          &:first-child {
            margin-bottom: 0.6rem;
          }
          svg {
            display: inline-block;
            margin-right: 1.7rem;
            width: 15px;
            height: 24px;
            position: relative;
            top: 8px;
          }
          em {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
