@import "./../../utils/scss/mixins.scss";

.verify-container {
  text-align: center;
  padding: 4rem 1.6rem;
  @include error_text_style;
  aside {
    display: none;
  }

  main > button {
    width: 100%;
    padding: 1.5rem;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #fffffe;
    background: #e7aa07;
    border-radius: 10px;
    border: none;
    margin: 3rem 0;
  }
  .verify {
    h1 {
      font-weight: 600;
      font-size: 40px;
      line-height: 51px;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 1rem;
    }
    em {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #2d334a;
      display: block;
      margin-bottom: 4.4rem;
    }

    form {
      text-align: left;

      label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #2d334a;
        margin-bottom: 1.6rem;
        display: block;

        a {
          color: #2d334a;
        }

        span {
          color: rgba(255, 81, 65, 1);
        }

        textarea,
        input,
        select {
          resize: none;
          display: block;
          padding: 1rem;
          border: 2px solid #d2d2d2;
          border-radius: 8px;
          margin-top: 0.8rem;
          width: 100%;
          &::placeholder {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #d2d2d2;
          }
        }
      }
      button,
      em {
        background: transparent;
        border: none;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: rgba(45, 51, 74, 1);
        display: inline-block;
      }
      em {
        color: rgba(139, 139, 139, 1);
      }
      button {
        margin-left: 0.5rem;
      }
    }
  }
}

@media screen and (min-width: 650px) {
  .verify-container {
    main > button {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
  .verify {
    & > button {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    form {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
}

@media screen and (min-width: 1200px) {
  .verify-container {
    padding: 0;
    display: flex;
    aside {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 4rem 0;
      width: 512px;
      img {
        width: 80%;
      }
    }

    main {
      width: 45%;
      max-width: 600px;
      padding: 8rem 0;
      margin-left: auto;
      margin-right: auto;

      .verify {
        form {
          max-width: 600px;
        }
      }
    }
  }
}
