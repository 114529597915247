.money-form {
  position: relative;
  text-align: center;
  padding: 1.6rem;
  .info {
    margin-bottom: 4rem;
    text-align: left;
    label {
      display: block;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 3rem;
    }
    input,
    select {
      display: block;
      border: 1px solid #d2d2d2;
      border-radius: 7px;
      padding: 1rem;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #535353;
      margin-top: 0.8rem;
      width: 100%;
      &::placeholder {
        color: #e1e1e1;
      }
    }
  }
  button {
    background: #e7aa07;
    border-radius: 47px;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 37px;
    color: #ffffff;
    width: 160px;
    padding: 1rem 0;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.answer {
  padding: 0 1.6rem;
  margin-bottom: 4rem;
  overflow: hidden;
  max-height: 0;
  transition: max-height ease-in-out 0.5s;
  &.active {
    max-height: 425px;
  }
  div.from,
  div.to {
    color: #272343;
    em {
      margin: 0;
      &:first-child {
        font-weight: 600;
        font-size: 19px;
        line-height: 24px;
      }
      &:last-child {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #d2d2d2;
      }
    }
    h4 {
      font-weight: 800;
      font-size: 40px;
      line-height: 50px;
      text-align: center;
      margin-bottom: 1rem;
    }
    div {
      padding: 1.6rem;
      border: 1px solid #7165e3;
      border-radius: 11px;
      margin-top: 2rem;
    }
  }

  div.from {
    margin-bottom: 3rem;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
  div.to {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
  div.transfer {
    margin-bottom: 3rem;
    img {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
}

@media screen and (min-width: 500px) {
  .money-form {
    .info {
      label {
        margin-left: auto;
        margin-right: auto;
        max-width: 350px;
      }
    }
  }
  .answer {
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;

    div.from {
      margin-left: 0;
    }
    div.to {
      margin-right: 0;
    }
  }
}

@media screen and (min-width: 600px) {
  .money-form {
    .info {
      label,
      select,
      input {
        font-size: 16px;
      }
    }
  }
}

@media screen and (min-width: 750px) {
  .money-form {
    .info {
      label {
        max-width: 500px;
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .answer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: unset;
    &.active {
      max-height: 185px;
    }
    div.from,
    div.to {
      margin: 0;
    }
    div.transfer {
      background: #ffffff;
      border: 1px solid rgba(210, 210, 210, 0.25);
      box-shadow: 0px 2px 8px rgba(210, 210, 210, 0.25);
      border-radius: 17px;
      width: 90px;
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 3rem;
    }
  }
}

@media screen and (min-width: 1200px) {
  .money-form {
    position: relative;
    text-align: center;
    border: 1px solid #d2d2d2;
    border-radius: 14px;
    padding: 4.8rem;
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4rem;
      text-align: left;
      label {
        font-weight: 600;
        font-size: 19px;
        line-height: 24px;
        text-transform: capitalize;
        color: #272343;
      }
      input,
      select {
        display: block;
        border: 1px solid #d2d2d2;
        border-radius: 7px;
        padding: 2rem;
        font-weight: 500;
        font-size: 19px;
        line-height: 23px;
        color: #535353;
        margin-top: 1.5rem;
        width: 260px;
        &::placeholder {
          color: #e1e1e1;
        }
      }
    }
    button {
      border-radius: 47px;
      border: none;
      font-weight: 700;
      font-size: 19px;
      line-height: 37px;
      color: #ffffff;
      width: 230px;
      padding: 1.8rem 0;
    }
  }
  .answer {
    div.from,
    div.to {
      width: 380px;
      div h4,
      div em,
      & > em {
        text-align: left;
        padding: 0;
      }
    }
  }
}
