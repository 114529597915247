.overview-container {
    // margin: 0 auto;
    background-color: white;
    // padding: 1rem;
    border-top: 1px solid #d2d2d2;
    color: #272343;
    
    h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      color: #272343;
      margin-bottom: 0.8rem;
    }
    h3 {
      font: 600 20px 'plus jakarta sans';
      line-height: 25.2px;
    }
     p {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      // text-align: center;
      font-family: 'Lato';
      color: #272343;
      // margin-bottom: 3.2rem;
    }
    hr {
      border: 0.1px solid #d2d2d2;
      margin-top: 1rem;
    }

    span {
      font: 500 12px 'Plus jakarta sans';
      color: #aeaeae;
    }

    section.theOverview {
        display: flex;
        // height: 100vw;
        .mainflex {
            width: 100%;
            padding: 2.5rem 4.5rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .welcome {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              p.greet {
                font: 600 18px 'lato';
                color: #272343;
              }
              div {
                flex: 0 0 60%;
                justify-content: space-between;
                display: flex;
                padding: 0 1rem;
                align-items: center;
                p {
                  color: #E7AA07;
                }
                button {
                  padding: 12px 24px;
                  border-radius: 8px;
                  background-color: #00751F;
                  p {display: flex; gap: 1rem; color: #fff;}
                }
              }
            }
            .stats {
              display: flex;
              gap: 2rem;
              width: 100%;
              .bal {
                padding: 2.5rem;
                border: 0.5px solid #d2d2d2;
                border-radius: 12px;
                // width: 30%;
                width: 290px;
                height: 110px;
                display: flex;
                justify-content: space-between;
                div {
                  > p:first-child {
                    color: #aeaeae;
                    font-size: 16px;
                  }
                  > p:last-child {
                    font: 700 32px 'plus jakarta sans';
                    line-height: 40px;
                  }
                  span {
                    display: flex;
                    align-items: center;
                    gap: 0.2rem;
                    font-family: 'Lato';
                    color: #00751F;
                  }
                }
              }
            }
            .props {
              display: flex;
              gap: 2rem;
              width: 100%;
              .leftside {
                flex: 0 0 70%;
                display: flex;
                flex-direction: column;
                gap: 3rem;
                .details {
                  display: flex;
                  padding: 20px 32px;
                  border-radius: 8px;
                  border: 0.5px solid #d2d2d2;
                  width: 100%;
                  flex-direction: column;
                  div.search {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    button {
                      border: 0.5px solid #d2d2d2;
                      padding: 6px 14px;
                      border-radius: 6px;
                      width: 67px;
                      height: 35px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      color: #aeaeae;
                    }
                    div {
                      border: 0.5px solid #d2d2d2;
                      padding: 6px 15px;
                      border-radius: 6px;
                      width: 193px;
                      height: 35px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      color: #aeaeae;
                    }
                  }
                  div.title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 40px;
                    > p:first-child {
                      width: 26%;
                    }
                  }
                  div.dets {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 1rem;
                    > div:first-child {
                      // width: 30%;
                      display: flex;
                      gap: 1rem;
                      flex: 0 0 35%;
                    }
                    p {
                      font: 600 12px 'lato';
                    }
                    div.middle {
                      display: flex;
                      flex-direction: column;
                      gap: 1rem;
                      // align-items: center;
                      p, span { font-size: 14px;}
                    }
                    > div:last-child {
                      display: flex;
                      flex-direction: column;
                      gap: 1rem;
                      align-items: center;
                      > p:first-child {
                        font-size: 14px;
                      }
                      > p:last-child {
                        background-color: #4799511A;
                        padding: 8px 12px;
                        border-radius: 23px;
                        color: #00751F;
                      }
                    }
                  }
                }
              }
              .rightside {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                width: 100%;
                button {
                  padding: 12px 26px;
                  border-radius: 8px;
                  background-color: #272343;
                  p {color: #fff; display: flex; justify-content: center; align-items: center; gap: 1rem;}
                }
              }
            }
        }
    }
  
  }
  
  
//   @media screen and (min-width: 1200px) {
//     .overview-container {
//       // max-width: 1200px;
  
//       & > p {
//         max-width: 550px;
//         margin-left: auto;
//         margin-right: auto;
//       }
      

//     }
//   }
  

  @media screen and (max-width: 900px) {
    .overview-container {
      .theOverview {
        .side {
          display: none;
        }
      }
    }
  }

  // @media screen and (max-width: 800px) {
  //   .overview-container {

  //   }
  // }

//   @media screen and (max-width: 700px) {
//     .overview-container {
      

//     }
//   }
  
