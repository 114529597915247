.side {
    flex: 0 0 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #d2d2d2;
    margin-top: 1rem;
    gap: 15rem;
    .theFirstHalf {
      width: 100%;
      margin-top: 6rem;
      div.real {
        display: flex;
        gap: 1rem;
        background-color: #272343;
        padding: 24px 80px 24px 64px;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
        p {
            color: #fff;
            font: 600 16px 'plus jakarta sans';
            line-height: 20px;
        }
      }
      div.faded {
          display: flex;
          gap: 1rem;
          padding: 24px 80px 24px 64px;
          justify-content: flex-start;
          width: 100%;
          align-items: center;
          p {
              color: #aeaeae;
              font: 600 16px 'plus jakarta sans';
              line-height: 20px;
          }
      } a :hover {
        background-color: #272343;
      }
    }
    .theSecondHalf {
      width: 100%;
      // margin-top: 8rem;
      div.real {
        display: flex;
        gap: 1rem;
        background-color: #272343;
        padding: 24px 80px 24px 64px;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
        p {
            color: #fff;
            font: 600 16px 'plus jakarta sans';
            line-height: 20px;
        }
      }
      div.faded {
          display: flex;
          gap: 1rem;
          padding: 24px 80px 24px 64px;
          justify-content: flex-start;
          width: 100%;
          align-items: center;
          p {
              color: #aeaeae;
              font: 600 16px 'plus jakarta sans';
              line-height: 20px;
          }
      } a :hover {
        background-color: #272343;
      }
    }
    
    
}